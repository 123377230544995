export function viewAnimation () {
  // Components loading animations
  $('.view-animation').viewportChecker({
    classToAdd: 'animated',
    offset: 20
  })
}

export function smoothScroll() {
  let blacklist = [
    '#report',
    '#claim',
    '#enquiry'
  ]
  $(document).on('click', 'a[href*="#"]:not([href="#"]):not([data-bs-toggle])', function (event) {
      var target = $(this).attr('href');

      if(blacklist.some( (check) => {
        return target == check
      })) {
        return false;
      }

      if ($(target).length) {
          event.preventDefault();

          $('html, body').animate({
              scrollTop: $(target).offset().top - ( $('.site-header').outerHeight() + 200 )
          }, 600);
      }
  });
}

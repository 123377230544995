// a very elaborate tracking trigger setup for paid social
// on the fwr ty page, if someone clicks the green button to open the form, and submits the form
// the conversion is to be tracked on the following page in gtm
export default function fwrGBTNChecker(){
	if(window.location.href.indexOf('thank-you-fwr') > -1){
		let greenBtnClicked = false;
		let greenButton = document.querySelector('.page-content a[href="#claim"]');

		if(greenButton){
			greenButton.addEventListener('click', function(){
				greenBtnClicked = true;
			});
		}

		let wpcf7Elm = document.querySelector( '[name="_wpcf7"][value="872"]' ).closest('.wpcf7');
	 
		wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
			if(greenBtnClicked){
				sessionStorage.setItem('fwrgbtnsuccess', 'yes');
			}
		}, false );
	}

	// if normal ty page, fire it
	if(window.location.href.indexOf('//aquaclearws.com/thank-you/') > -1){
		if(typeof dataLayer !== "undefined" && sessionStorage.getItem('fwrgbtnsuccess') == 'yes'){
			dataLayer.push({
				event: 'gtm.fwrgbtnCheck'
			});

			// reset it
			sessionStorage.setItem('fwrgbtnsuccess', 'no');
		}
	}
}

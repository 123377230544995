import lityFunc from '../vendor/lity';

export function inlineVideo() {
    const lity = lityFunc();

    $(document).on('click', '.inline-video-trigger', function () {
        if ($(this).data('video-id')) {
            var iframeSrc = 'https://player.vimeo.com/video/'  + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1';
            
            if (!$(this).hasClass('vimeo')) {
                iframeSrc = 'https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1&rel=0'
            }
            lity(iframeSrc)
        } else {
            console.error('no video ID provided.');
        }
    });
}
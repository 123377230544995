export default function addUTM() {
	const params = new URLSearchParams(window.location.search);

    params.forEach(function(val, key){
        if(key.includes('utm_')){
            sessionStorage.setItem(key, val);
        }
    });

    let utmFields = document.querySelectorAll('input[name*="utm_"]');

    if(utmFields.length){
        utmFields.forEach(function(el, index){
            if(sessionStorage.getItem(el.getAttribute('name'))){
                el.value = sessionStorage.getItem(el.getAttribute('name'));
            }
        });
    }
}
import { Modal } from 'bootstrap'

export default function modal() {
	let modalLinks = [
		{
			selector: '[href*="#enquire"]',
			modal: 'enquiry',
		},
		{
			selector: '[href*="#claim"]',
			modal: 'claim',
		},
		{
            selector: '[href*="#water-test"]',
			modal: 'water-test',
        },
	]

	modalLinks.forEach((link) => {
		let links = document.querySelectorAll(link.selector)

		if (links.length) {
			for (var i = 0; i < links.length; i++) {
				links[i].addEventListener('click', (evt) => {
					evt.preventDefault()
					let target = document.querySelector('#' + link.modal)
					new Modal(target).show()
				})
			}
		}
	})

	let modals = document.querySelectorAll('.modal')

	if (modals.length) {
		for (var i = 0; i < modals.length; i++) {
			new Modal(modals[i])
		}
	}
}

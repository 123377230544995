import { accordion } from './components/accordion'
import { swiperDesktop, swiperMobile } from './components/swiper'
import {
	hamburger,
	headerHeight,
	sidebarNav,
	stickyHeader,
} from './components/header'
import { viewAnimation } from './util/animation'
import { outputMonth, countDown } from './util/dates'
import { inlineVideo } from './util/video'
import { phoneConcat, readMore } from './util/text'
import { cf7Redirect } from './util/window'
import modal from './components/modal'
import dateFields from './components/form'
import collapse from './components/collapse'
import { smoothScroll } from './util/animation'
import addUTM from './util/addUTM'
import fwrGBTNChecker from './components/fwrgbtn'

;(function ($) {
	$(window).on('DOMContentLoaded', function () {
		document.body.classList.add('loaded')
		collapse()
		modal()
		dateFields()
		sidebarNav()
		headerHeight()
		stickyHeader()
		hamburger()
		outputMonth()
		viewAnimation()
		accordion()
		swiperDesktop()
		swiperMobile()
		inlineVideo()
		phoneConcat()
		cf7Redirect()
		smoothScroll()
		addUTM()
		fwrGBTNChecker()
	})
})(jQuery)

export function sidebarNav() {
	var menuItemsWithChildren = document.querySelectorAll(
		'.site-header__menu-popover nav > ul > li.menu-item-has-children > a'
	)
	var menuItemsWithGrandchildren = document.querySelectorAll(
		'.site-header__menu-popover nav > ul > li.menu-item-has-children > ul > li.menu-item-has-children > a .nav-accordion'
	)
	var menuItemsWithTransition = document.querySelectorAll(
		'.site-header__menu-popover .nav-box > ul > li'
	)

	menuItemsWithGrandchildren.forEach(function (el, index) {
		el.addEventListener('click', function (e) {
			let parent = el.parentNode.parentNode;
			if(!parent.classList.contains('child-active') || e.target.tagName != 'A') {
				e.preventDefault()
			} else {
				return;
			}

			let submenu = parent.querySelector('.sub-menu')
			let height = 0

			if (submenu && !parent.classList.contains('child-active')) {
				height = submenu.scrollHeight
			}
			submenu.style = `max-height: ${height}px`

			parent.classList.toggle('child-active')
			parent.parentNode.classList.add('transitioning')
			el.closest('.nav-box').classList.toggle('level-2-active')
		})
	})

	menuItemsWithChildren.forEach(function (el, index) {
		el.addEventListener('click', function (e) {
			if(!el.closest('.nav-box').classList.contains('level-1-active') || e.target.tagName != 'A') {
				e.preventDefault()
			} else {
				return;
			}

			let hideables = [
				document.querySelector('.nav-box--small'),
				document.querySelector('.site-header__menu-popover__cta'),
			]

			el.parentNode.classList.toggle('child-active')

			if (
				el.parentNode.parentNode.querySelector('.child-active') &&
				hideables
			) {
				hideables.map((el) => {
					el.classList.remove('active')
				})
			} else {
				hideables.map((el) => {
					el.classList.add('active')
				})
			}

			el.parentNode.parentNode.classList.add('transitioning')
			el.closest('.nav-box').classList.toggle('level-1-active')
		})
	})

	// add/remove class when transitions end
	menuItemsWithTransition.forEach(function (el, index) {
		_transitionEndEvents(el, function () {
			el.parentNode.classList.remove('transitioning')
		})
	})

	document.body.addEventListener('click', function (e) {
		var target = e.target

		if (document.body.classList.contains('menu-open')) {
			if (
				!target.classList.contains('site-header__menu-popover') &&
				!target.classList.contains('site-header__hamburger') &&
				!target.closest('.site-header__menu-popover') &&
				!target.closest('.site-header__hamburger')
			) {
				document.body.classList.remove('menu-open')
			}
		}
	})

	// set height of nav box
	// var navBox = document.querySelector('.sidebar-nav .nav-box');
	// navBox.style.height = window.innerHeight - navBox.getBoundingClientRect().top + 'px';
	// window.addEventListener('resize', function(){
	//     navBox.style.height = window.innerHeight - navBox.getBoundingClientRect().top + 'px';
	// });

	function _transitionEndEvents(el, func) {
		var events = [
			'transitionend',
			'oTransitionEnd',
			'transitionend',
			'webkitTransitionEnd',
		]
		for (var i = 0; i < events.length; i++) {
			el.addEventListener(events[i], func)
		}
	}
}

export function hamburger() {
	$('.site-header__hamburger, .site-header__menu-popover__close').on(
		'click',
		function () {
			$('body').toggleClass('menu-open')
		}
	)
}

export function stickyHeader() {
    
    let headerScroll = () => {
        if ($(window).scrollTop() >= 50) {
			$('.site-header').addClass('scrolled')
		} else {
			$('.site-header').removeClass('scrolled')
			headerHeight();
		}
    }

	$(window).on('scroll', headerScroll)
    headerScroll();
}

export function headerHeight() {
	const main = document.querySelector('main')
	var setHeight = () => {
		let main = document.querySelector('main');
		var headerHeight = document.querySelector('.site-header')
		if (main && window.disableHeaderPadding != true) {
			main.setAttribute(
				'style',
				'padding-top: ' + headerHeight.offsetHeight + 'px;'
			)
		}
	}
	if (typeof main !== 'undefined') {
		setHeight()
		window.addEventListener('resize', setHeight)
		window.addEventListener('scroll', setHeight)
	}
}

export function cf7Redirect() {

    var wpcf7Elm = document.querySelectorAll( '.wpcf7' );
    if(wpcf7Elm){

        for(var i = 0; i < wpcf7Elm.length; i++) {
            wpcf7Elm[i].addEventListener( 'wpcf7mailsent', function( event ) {
                var elm = this;
                var inputVal = elm.querySelector('[type=submit]');
                var redirect = elm.querySelector('[name="kk-redirect"]')

                inputVal.value = 'Complete!';

                if(redirect){
                    window.location.href = redirect.value;
                }
            });

            wpcf7Elm[i].addEventListener('submit', function(event) {
                var inputVal = this.querySelector('[type=submit]');
                
                if(inputVal) {
                    inputVal.setAttribute('data-value', inputVal.value);
                }
                inputVal.value = 'Processing...';

            })


            wpcf7Elm[i].addEventListener('wpcf7invalid', function() {
                var inputVal = this.querySelector('[type=submit]');

                if(inputVal && inputVal.getAttribute('data-value')) {
                    inputVal.value = inputVal.getAttribute('data-value');
                }
            })
        }
       
    }

 }   
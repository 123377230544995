export default function dateFields() {
    let dates = document.querySelectorAll('[type=date]');

    dates.forEach((date) => {
        let placeholder = date.getAttribute('placeholder');
        let label = document.createElement('span');
        label.classList.add('date-placeholder');
        if(placeholder) {
            label.innerHTML = placeholder;
            date.parentNode.append(label);
            date.addEventListener('focus', () => {
                label.classList.add('inactive');
            })
            date.addEventListener('blur', () => {
                if(date.value == '') {
                    label.classList.remove('inactive');
                }
            })
        }
    })
}